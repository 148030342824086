import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Heading from 'components/shared/heading';
import Categories from 'components/shared/categories';
import Link from 'components/shared/link';

import styles from './items.module.scss';

const cx = classNames.bind(styles);

const Items = ({ items, categories, pageRootPath, activeCategoryId, labelCategoryAll }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <Categories
        className={cx('categories')}
        categories={categories}
        pageRootPath={pageRootPath}
        activeCategoryId={activeCategoryId}
        labelCategoryAll={labelCategoryAll}
        labelMobileCategoryAll={labelCategoryAll}
      />

      <div className={cx('items')}>
        {items.map(
          (
            {
              title,
              acf: {
                link: { url },
                description,
                image,
              },
            },
            index
          ) => (
            <Link className={cx('item')} key={index} to={url}>
              <div className={cx('image-wrapper')}>
                <GatsbyImage
                  image={getImage(image.localFile.childImageSharp)}
                  alt={image.altText}
                />
              </div>
              <div className={cx('content')}>
                <div>
                  <Heading className={cx('title')} tag="h3" size="lg">
                    {title}
                  </Heading>
                  <p className={cx('description')}>{description}</p>
                </div>
                <div className={cx('footer')}>
                  <span className={cx('read-more')}>Read more</span>
                </div>
              </div>
            </Link>
          )
        )}
      </div>
    </div>
  </section>
);

Items.defaultProps = {
  activeCategoryId: '',
  labelCategoryAll: 'All Case Studies',
};

Items.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      acf: PropTypes.shape({
        link: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
              }),
            }),
          }),
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageRootPath: PropTypes.string.isRequired,
  activeCategoryId: PropTypes.string,
  labelCategoryAll: PropTypes.string,
};

export default Items;
