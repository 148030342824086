/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import Hero from 'components/pages/case-studies/hero';
import Items from 'components/pages/case-studies/items';
import Pagination from 'components/pages/blog/pagination';

const CaseStudies = ({ data: { wpPage: data, posts }, pageContext, location }) => (
  <LayoutMain
    seo={{ ...data.seo, date: pageContext.date }}
    footerTheme="with-border"
    withBanner={pageContext.showBanner}
    withBottomOffset
    pageContext={pageContext}
    location={location}
  >
    <Hero
      title={data.acf.heroCaseStudies.title}
      description={data.acf.heroCaseStudies.description}
    />

    <Items
      title={data.title}
      items={posts.nodes}
      categories={pageContext.categories}
      pageRootPath={data.uri}
      activeCategoryId={pageContext.categoryId}
    />
    {pageContext.pageCount > 1 && (
      <Pagination
        pageCount={pageContext.pageCount}
        currentPageIndex={pageContext.currentPageIndex}
        pageRootPath={data.uri}
        categoryPath={pageContext.categoryPath}
      />
    )}
  </LayoutMain>
);

export default CaseStudies;

export const query = graphql`
  query ($id: String!, $categoryId: String, $skip: Int!, $limit: Int!) {
    wpPage(id: { eq: $id }) {
      uri
      title
      acf {
        heroCaseStudies {
          title
          description
        }
      }
      ...wpPageSeo
    }
    posts: allWpCaseStudy(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        categories {
          nodes {
            name
          }
        }
        acf {
          link {
            url
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 230)
              }
            }
          }
          description
        }
      }
    }
  }
`;
